/* You can add global styles to this file, and also import other style files */
@import '@leafio/styles/base';
@import '@leafio/styles/lf-icons';

// Overwrite @leafio/styles default vars
:root {
  --lf-h1-font-size: 36px;
  --lf-h1-line-height: 1.3;
}

:root {
  --aside-layout-content-max-width: 420px;
  --aside-layout-content-min-width: 375px;

  --aside-layout-content-paddings: 70px;

  --aside-banner-max-width: 660px;
  --aside-banner-max-height: 452px;

  --aside-banner-min-width: 460px;
  --aside-banner-min-height: 352px;

  --aside-banner-animation-duration: 10s;
  --aside-banner-corner-radius: 180px;
  --aside-banner-foreground-offset: -60px;
}

html,
body {
  min-height: 100vh;
}

body {
  overflow: hidden;
}

.app-title {
  margin-bottom: 24px;
}

.app-subtitle {
  margin-bottom: 44px;
  line-height: 110%;
}

@media screen and (width <= 1152px) {
  .app-title {
    font-size: 28px;
  }

  .app-subtitle {
    font-size: 14px;
    line-height: 115%;
  }
}

@media screen and (width <= 768px) {
  .app-title {
    font-size: 22px;
  }

  .app-subtitle {
    line-height: 140%;
  }
}

.app-error-message,
.app-failed-login {
  font-size: 12px;
  font-weight: 500;
  color: var(--lf-danger-500);
  position: absolute;
}

.app-error-message {
  top: 140px;
}

.app-failed-login {
  text-align: center;
  padding-top: 5px;
  left: 0;
  right: 0;
}

.app-btn {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background-color: var(--lf-success-500);
  box-shadow: 0px 16px 16px -8px rgba(61, 202, 54, 0.24);

  width: 100%;
  border: none;
  outline: none;
  font-family: var(--lf-font-family);
  font-weight: 700;
  font-size: 20px;
  color: var(--lf-text-100);
  cursor: pointer;
  margin-top: 44px;
  transition-duration: var(--lf-transition-duration);
  transition-property: backround-color, color, opacity, border-color;

  &:disabled {
    background-color: var(--lf-success-200);
    cursor: not-allowed;
  }
}
